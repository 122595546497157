import React from "react"
import Footer from "./footer/footer";
import Header from "./shared/header"

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: '100vw',
        }}
      >
        <main>{children}</main>
      </div>
      <Footer siteTitle={`Title`} />
     
    </>
  )
}

export default Layout
